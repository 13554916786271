import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: '/',
        name: 'mainpage',
        component: () => import('./pages/MainPage.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('./pages/PAbout.vue')
    },
    {
        path: '/tradingplatform',
        name: 'tradingplatform',
        component: () => import('./pages/TradingPlatform.vue')
    },
    {
        path: '/FAQ',
        name: 'faq',
        component: () => import('./pages/PFAQ')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('./pages/PContact')
    },
    {
        path: '/bubbles',
        name: 'bubbles',
        component: () => import('./components/UI/logoBubbles.vue')
    },
    {
        path: '/location',
        name: 'location',
        component: () => import('./components/UI/locationItems.vue')
    },

    { path: "/:notFound(.*)", component: () => import('./pages/NotFound.vue') },
    { path: "/comingsoon", component: () => import('./pages/ComingSoon.vue') },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 }
    }
})

export default router;