<template>
  <div class="footer">
    <div class="footer-left">
      <NormalButton
        label="Azfinance.az-a keçid et"
        @click="directToAzFinance"
        class="footer-button outlined"
      ></NormalButton>
    </div>

    <div class="footer-center">
      <p class="footer-text">Bütün hüquqlar qorunur © 2022</p>
    </div>

    <div class="footer-right">
      <div>
        <div class="footer-icons">
          <NormalButton
            icon="pi pi-facebook"
            class="footer-icon"
            @click="goToFacebook"
          ></NormalButton>
          <NormalButton
            icon="pi pi-instagram"
            class="footer-icon"
            @click="goToInstagram"
          ></NormalButton>
          <NormalButton
            icon="pi pi-linkedin"
            class="footer-icon"
            @click="goToLinkedin"
          ></NormalButton>
          <NormalButton
            icon="pi pi-whatsapp"
            class="footer-icon"
            @click="goToWhatsapp"
          ></NormalButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    directToAzFinance() {
      window.location.href = "https://azfinance.az/";
    },
    goToFacebook() {
      window.open(
        "https://www.facebook.com/profile.php?id=100089577974872",
        "_blank"
      );
    },
    goToInstagram() {
      window.open("https://www.instagram.com/traderazplatform/", "_blank");
    },
    goToLinkedin() {
      window.open(
        "https://www.linkedin.com/company/azfinance-invest/mycompany/",
        "_blank"
      );
    },
    goToWhatsapp() {
      window.open(
        "https://api.whatsapp.com/send/?phone=994502905767",
        "_blank"
      );
    },
  },
};
</script>
<style scoped>
.footer {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  height: 100%;
  margin: 0 auto;
  padding: 0 auto;
  flex-wrap: nowrap;
}
.footer-right {
  display: flex;
  width: 250px;
  justify-content: end;
}
.footer-left {
  display: flex;
  width: 250px;
  justify-content: start;
}
.footer-button {
  background: transparent;
  border: 1px solid white;
  border-radius: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 18px;
}

.footer-icon {
  padding: 15px;
}

.footer-button:hover {
  background: #4f46e5 !important;
  border: 1px solid #4f46e5 !important;
}
.footer-text {
  font-weight: 400;
  font-size: 16px;
}

.footer-contact {
  font-weight: 700;
  font-size: 20px;
}
.footer-icons {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

@media screen and (max-width: 775px) {
  .footer {
    display: block;
    text-align: center;
  }
  .footer-text {
    margin-bottom: 10px;
  }
  .footer-right {
    display: block;
    margin-top: 5px;
    width: 100%;
  }
  .footer-icons {
    display: block;
    margin-top: 5px;
    margin-left: 0px;
  }
  div.footer-left {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .p-button {
    margin: 0px auto;
  }
  .footer-icons {
    width: 100%;
    margin: 0px auto !important;
  }
}
</style>
