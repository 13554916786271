<template>
  <div class="container">
    <ScrollTop />

    <div class="navbar">
      <NavBar></NavBar>
    </div>
    <div class="content">
      <router-view> </router-view>
    </div>

    <div class="footer-div">
      <TradeFooter class="footer"></TradeFooter>
    </div>
  </div>
</template>

<script>
import NavBar from "./components/Navbar/NavBar.vue";
import TradeFooter from "./components/Footer/TradeFooter.vue";
export default {
  name: "App",
  components: {
    NavBar,
    TradeFooter,
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
}
.content {
  margin-bottom: 20px;
}
.footer-div {
  height: 110px;
  width: 100%;
  margin-top: auto;
}
.navbar {
  margin-top: 18px;
}
</style>
