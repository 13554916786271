<template>
  <div class="navbar">
    <nav>
      <div class="brand">
        <router-link class="navigation-link" to="/">
          <img src="images/traderaz.png" alt="" />
        </router-link>
      </div>
      <ul v-show="!mobile" class="navigation">
        <li><router-link class="navigation-link" to="/about">Haqqımızda</router-link></li>
        <li>
          <router-link class="navigation-link" to="/tradingplatform"
            >Ticarət Platforması</router-link
          >
        </li>
        <li><router-link class="navigation-link" to="/FAQ">FAQ</router-link></li>
        <li><router-link class="navigation-link" to="/contact">Əlaqə</router-link></li>
        <li class="navigation-phone">
          <i class="pi pi-phone"></i>
         <a  :href="`tel:${mobileNumber}`" style="color:white; text-decoration:none">{{mobileNumber}}</a>
        </li>
      </ul>
      <div class="navigation-icon">
        <i
          v-show="mobile"
          @click.prevent="toggleMobileNavigation"
          class="pi pi-bars"
          :class="{ 'icon-active': mobileNavigation }"
        ></i>
      </div>
      <transition name="navigation-mobile">
        <ul v-show="mobileNavigation" class="navigation-dropdown">
          <li>
            <router-link class="navigation-link" to="/about">Haqqımızda</router-link>
          </li>
          <li>
            <router-link class="navigation-link" to="/tradingplatform"
              >Ticarət Platforması</router-link
            >
          </li>
          <li><router-link class="navigation-link" to="/FAQ">FAQ</router-link></li>
          <li><router-link class="navigation-link" to="/contact">Əlaqə</router-link></li>
          <li>
            <router-link to="/" class="navigation-link">
              <i class="pi pi-phone"></i>
              *7667</router-link
            >
          </li>
        </ul>
      </transition>
    </nav>
  </div>
</template>
<script>
export default {
  name: "NavBar",
  data() {
    return {
      mobile: null,
      mobileNavigation: null,
      windowWidth: null,
      mobileNav: null,
      mobileNumber: '*7667'
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreenWidth);
    this.checkScreenWidth();
  },
    mounted () {
    document.addEventListener('click', this.close)
  },
  beforeUnmount () {
    document.removeEventListener('click',this.close)
  },
  methods: {
    toggleMobileNavigation() {
          this.mobileNavigation = !this.mobileNavigation
      },
      close(e) {
        if (!this.$el.contains(e.target)) {
          this.mobileNavigation = false
        }

      },
    
    checkScreenWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 900) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNavigation = false;
      return;
    },
  },
};
</script>
<style scoped>
.navbar {
  width: 100%;
  transition: 0.5s ease all;
  color: white;
  z-index: 100;
  position: relative;
}
nav {
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  transition: 0.5s ease all;
  width: 100%;
  margin: 0 auto;
  @media (min-width: 1140px) {
    max-width: 1140px;
  }
}
ul,
.navigation-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  list-style: none;
  text-decoration: none;
  color: white;
}

li {
  padding: 16px;
  margin-left: 16px;
}

.navigation-link:hover {
  color: #6366f1;
  cursor: pointer;
}

.brand {
  display: flex;
  align-items: center;
}
.brand img {
  width: 210px;
  height: auto;
}
img {
  margin-top: -5px;
}

.navigation {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.navigation-icon {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 24px;
  height: 100%;
}

.navigation-icon {
  cursor: pointer;
  font-size: 24px;
  transition: 0.8s ease all;
}
.icon-active {
  transform: rotate(180deg);
}
.navigation-dropdown {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  max-width: 250px;
  height: 100%;
  background-color: white;
  top: 0;
  left: 0;
}

.navigation-dropdown li {
  margin-left: 0;
}

.navigation-dropdown li .navigation-link {
  color: black;
}

.navigation-phone {
  font-weight: 700;
  font-size: 24px;
}
.navigation-phone i {
  font-weight: 700;
  font-size: 24px;
}
@media screen and (min-width:1200px) {
  .navigation-link {
  padding-right: 40px;
}
.navigation-link:nth-child(5) {
  padding-right: 0px !important;
}
}

.router-link-active{
  color: #6366f1;
}

</style>
