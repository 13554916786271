import { createApp, h } from 'vue';
import App from './App.vue'

const app = createApp({
    render() { return h(App); }
});

import router from './router';

import PrimeVue from 'primevue/config';
import NormalButton from 'primevue/button';
import PrimeCarousel from 'primevue/carousel';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import ScrollTop from 'primevue/scrolltop';




import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/styling/general.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

app.use(PrimeVue);
app.use(VueAxios, axios);
app.use(router);


app.component('NormalButton', NormalButton);
app.component('PrimeCarousel', PrimeCarousel);
app.component('InputText', InputText);
app.component('TextArea', TextArea);
app.component('ScrollTop', ScrollTop);


app.mount('#app');
